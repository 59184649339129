import Categories from "./pages/categories/Categories";
import Category from "./pages/categories/Category";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Tag from "./pages/tags/Tag";
import Tags from "./pages/tags/Tags";
import Ingredient from "./pages/ingredients/Ingredient";
import Ingredients from "./pages/ingredients/Ingredients";
import Recipe from "./pages/recipes/Recipe";
import Recipes from "./pages/recipes/Recipes";
import User from './pages/admin/users/User'
import Users from './pages/admin/users/Users'
import Login from "./pages/login/Login";
import axios from "axios";
import SearchPage from "./pages/search/SearchPage";
import Reports from "./pages/reports/Reports";
import Subpages from "./pages/subpages/Subpages";
import Subpage from "./pages/subpages/Subpage";
import Articles from "./pages/articles/Articles";
import Article from "./pages/articles/Article";
import PublicRecipes from "./pages/public/recipes/Recipes";
import PublicRecipe from "./pages/public/recipes/Recipe";
import PublicArticles from "./pages/public/articles/Articles";
import PublicArticle from "./pages/public/articles/Article";
import PublicSubpage from "./pages/public/subpages/Subpage";

axios.interceptors.request.use(function (config) {
  if (config.skipInterceptor) {
    return config;
  }

  config.headers = {authorization: localStorage.getItem('authToken')}
  return config;
}, function (error) {
  return Promise.reject(error);
});


function App() {
  const role = localStorage.getItem('role')
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          {role === 'user' && (
            <>
              <Route path="/" element={<Recipes/>}/>
              <Route path="/category/:id?" element={<Category/>}/>
              <Route path="/categories" element={<Categories/>}/>
              <Route path="/tag/:id?" element={<Tag/>}/>
              <Route path="/tags" element={<Tags/>}/>
              <Route path="/ingredient/:id?" element={<Ingredient/>}/>
              <Route path="/ingredients" element={<Ingredients/>}/>
              <Route path="/recipe/:id?" element={<Recipe/>}/>
              <Route path="/recipes" element={<Recipes/>}/>
              <Route path="/search/:item/:query" element={<SearchPage/>}/>
              <Route path="/reports" element={<Reports/>}/>
              <Route path='/subpages' element={<Subpages/>}/>
              <Route path='/subpage/:id?' element={<Subpage/>}/>
              <Route path='/articles' element={<Articles/>}/>
              <Route path='/article/:id?' element={<Article/>}/>
            </>
          )}
          {role === 'admin' && (
            <>
              <Route path="/" element={<Users/>}/>
              <Route path="/user/:id?" element={<User/>}/>
              <Route path="/users" element={<Users/>}/>

            </>
          )}
          <Route path="/login" element={<Login/>}/>
          <Route path="/:email" element={<PublicRecipes/>}/>
          <Route path="/:email/recipes" element={<PublicRecipes/>}/>
          <Route path="/:email/recipe/:id" element={<PublicRecipe/>}/>
          <Route path="/:email/articles" element={<PublicArticles/>}/>
          <Route path="/:email/article/:id" element={<PublicArticle/>}/>
          <Route path="/:email/:id" element={<PublicSubpage/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  )
    ;
}

export default App;
