import {useEffect, useState} from "react";
import * as actions from "./ArticlesActions";
import {useParams} from "react-router-dom";
import parse from 'html-react-parser';
import Navbar from "../../../components/navbar/Navbar";
import Container from "../../../components/container/Container";
import './PublicArticle.scss'

const PublicArticle = () => {
  const [article, setArticle] = useState()
  const params = useParams()

  useEffect(() => {
    if (params.id) {
      actions.loadArticle(params, (data) => setArticle(data));
    }
  }, [params]);
  return(
    <div className='public-article'>
      <Navbar active='articles' />
      <Container>
        {article?.content && parse(article.content)}
      </Container>
    </div>
  )
}

export default PublicArticle