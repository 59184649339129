import React, {useEffect, useState} from "react";
import Button from "../../components/button/Button";
import FormTable from "../../components/form/formTable/FormTable";
import Input from "../../components/form/input/Input";
import Select from "../../components/form/select/Select";
import {loadIngredients} from "../ingredients/IngredientsActions";
import {useFormik} from "formik";
import * as yup from "yup";
import {PencilSquare, QuestionCircleFill, Trash} from "react-bootstrap-icons";
import Modal from "../../components/modal/Modal";

const IngredientsTable = ({ingredients=[], onChange}) => {
  const [selectedIngredient, setSelectedIngredient] = useState();
  const [ingredientOptions, setIngredientOptions] = useState([]);

  const formik = useFormik({
    initialValues: {
      id: null,
      amount: 0,
      ingredient: {
        id: null,
        name: "",
        unit: "",
        image_url: null
      }
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      onChange([...ingredients, {
        amount: values.amount,
        _destroy: false,
        ingredient: {
          id: values.ingredient.id,
          name: values.ingredient.name,
          unit: values.ingredient.unit,
          image_url: values.ingredient.image_url
        }
      }])

      if (selectedIngredient?.row_id) {
        const updatedIngredients = ingredients.map((item, index) =>
          index == selectedIngredient.row_id ? values : item
        );
        onChange(updatedIngredients);
      } else {
        onChange([...ingredients, values]);
      }
      setSelectedIngredient()
      formik.resetForm()
    },
    validationSchema: yup.object({
      ingredient: yup.object().shape({
        id: yup.number().required('Ingredient is required'),
      }),
      amount: yup.number()
        .required("Amount is required")
        .positive("Amount should be positive"),
    }),
    validateOnChange: false,
  });

  useEffect(() => {
    loadIngredients({all_records: true}, data => {
      setIngredientOptions(data.ingredients.map((ingredient) => ({
        ...ingredient,
        value: ingredient.id,
        label: ingredient.name,
      })));
    });
  }, []);

  const handleChangeIngredient = (selectedIngredient) => {

    formik.setValues({
      ...formik.values,
      ingredient: {
        id: selectedIngredient.value,
        name: selectedIngredient.label,
        unit: selectedIngredient.unit,
        image_url: selectedIngredient.image.image_url
      }
    })
  };

  const handleDelete = (ingredient, rowId) => {
    let updatedIngredients
    if (ingredient.id) {
      updatedIngredients = ingredients.map((item, index) => {
        if (index == rowId) {
          return {...item, _destroy: true};
        }
        return item
      });
    } else {
      updatedIngredients = ingredients.filter((_, index) => index != rowId)
    }

    onChange(updatedIngredients);
  };

  const handleEdit = (ingredient, rowId) => {
    setSelectedIngredient({...ingredient, row_id: rowId})
    formik.setValues(ingredient)
  }


  const columns = React.useMemo(() => [
    {
      Header: "Photo",
      accessor: "ingredient.image_url",
      disableSortBy: true,
      Cell: (tableProps) => (
        <div className="col">
          {tableProps.row.original.ingredient.image_url ? (
            <img
              src={tableProps.row.original.ingredient.image_url}
              alt="icon"
              className="image"
            />
          ) : (
            <QuestionCircleFill className="no-image-icon" size={40}/>
          )}
        </div>
      ),
    },
    {
      Header: "Ingredient",
      accessor: "ingredient.name",
      disableSortBy: true,
    },
    {
      Header: "Amount",
      accessor: "amount",
      disableSortBy: true,
    },
    {
      Header: "Unit",
      accessor: "ingredient.unit",
      disableSortBy: true,
    },
    {
      Header: "Options",
      Cell: (tableProps) => (
        <div className="options">
          <PencilSquare onClick={() => handleEdit(tableProps.row.original, tableProps.row.id)} size={20}/>
          <Trash onClick={() => handleDelete(tableProps.row.original, tableProps.row.id)} size={20}/>
        </div>
      ),
    },
  ], [ingredients]);

  return (
    <>
      <Modal
        isOpen={selectedIngredient}
        title={
          <div className="title">
            <span>Ingredients</span>
            <span>{`>`}</span>
            {selectedIngredient?.ingredient ? (
              <span>Edit - {selectedIngredient.ingredient.name}</span>
            ) : (
              <span>New</span>
            )
            }
          </div>
        }
        body={
          <>
            <Select
              onChange={(selectedIngredient) => handleChangeIngredient(selectedIngredient)}
              value={{value: formik.values.ingredient.id, label: formik.values.ingredient.name}}
              error={formik.errors.ingredient?.id}
              options={ingredientOptions}
              required
              name='name'
              label="Ingredient"
            />
            <Input
              required
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.errors.amount}
              step="0.01"
              name='amount'
              type='Number'
              label="Amount"
            />
          </>
        }
        footer={
          <>
            <Button variant='secondary' type='button' onClick={() => {setSelectedIngredient(); formik.resetForm() }}>
              Cancel
            </Button>
            <Button variant='primary' type='button' onClick={formik.handleSubmit}>
              Save
            </Button>
          </>
        }
        />
      <FormTable
        data={ingredients.filter(item => item._destroy !== true)}
        columns={columns}
        label='Ingredients'
        name='ingredient'
        onAdd={() => setSelectedIngredient([])}

      />
    </>
  );
};

export default IngredientsTable;