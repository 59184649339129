import {useEffect, useState} from "react";
import * as actions from "./SubpagesActions";
import {useParams} from "react-router-dom";
import parse from 'html-react-parser';
import Navbar from "../../../components/navbar/Navbar";
import Container from "../../../components/container/Container";
import './Subpages.scss'

const PublicSubpage = () => {
  const [subpage, setSubpage] = useState()
  const params = useParams()

  useEffect(() => {
    if (params.id) {
      actions.loadSubpage(params, (data) => setSubpage(data));
    }
  }, [params]);
  return(
    <div className='subpage'>
      <Navbar active={subpage?.id} />
      <Container>
        {subpage?.content && parse(subpage.content)}
      </Container>
    </div>
  )
}

export default PublicSubpage