import './Login.scss'
import Button from "../../components/button/Button";
import {EyeSlash, Eye, Bell} from "react-bootstrap-icons";
import {useState} from "react";
import {useFormik} from "formik";
import * as actions from './LoginActions'
import * as yup from "yup";
import classNames from "classnames";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState()
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: ""
  })


  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      actions.Login(values, (result) => {
        if (result.status === 200) {
          setError();
          localStorage.setItem('authToken', `${result.data.token_type} ${result.data.access_token}`);
          actions.CurrentUser((data) => {
            if (data.role == "admin") {
              window.location.replace('/users');
            } else {
              window.location.replace('/recipes');
            }
          })

        } else {
          setError("The Email or password are incorrect. Please try again.")
        }
      });
      formik.resetForm()
    },
    validationSchema: yup.object({
      email: yup.string().required("Email or Username are required"),
      password: yup.string().required("Password is required"),
    }),
    validateOnChange: false,
  });


  return (
    <div className='login'>
      <div className='image'>

      </div>
      <div className='form'>
        <form onSubmit={formik.handleSubmit}>
          <h2>NICE TO MEET YOU!</h2>
          {error && (<div className='error'><Bell size={30}/>{error}</div>)}
          <label>Login</label>
          <input
            name='email'
            type='text'
            className={classNames({invalid: formik.errors.email})}
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder={formik.errors.email ? formik.errors.email : 'E-mail or Username'}
          />
          <label>Password</label>
          <div className='password-container'>
            <input
              name='password'
              onChange={formik.handleChange}
              value={formik.values.password}
              type={showPassword ? 'text' : 'password'}
              className={classNames('password', {invalid: formik.errors.password})}
              placeholder={formik.errors.password ? formik.errors.password : '••••••'}
            />
            <button
              type='button'
              className={classNames({invalid: formik.errors.password})}
              onClick={() => setShowPassword((show) => !show)}
            >
              {showPassword ?
                <Eye size={20}/>
                :
                <EyeSlash size={20}/>
              }
            </button>
          </div>

          <Button type='submit' variant='primary'>Log In</Button>
        </form>
      </div>
    </div>
  )
}

export default Login