import Container from "../../../components/container/Container";
import {Cake2} from "react-bootstrap-icons";
import * as actions from "./RecipesActions.js";
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router";
import Navbar from "../../../components/navbar/Navbar";
import {useParams} from "react-router-dom";
import "./PublicRecipes.scss";

const PublicRecipes = () => {
  const navigate = useNavigate();
  const [recipes, setRecipes] = useState([]);
  const [year, setYear] = useState(2024)

  const params = useParams();

  useEffect(() => {
    actions.loadRecipes(
      {
        year: year,
        email: params.email,
      },
      (data) => {
        setRecipes(data);
      }
    );
  }, [params.email, year]);

  const splitRecipes = (recipes) => {
    if (!recipes || recipes.length === 0) return [[], [], []];
    if (recipes.length === 1) return [recipes,[],[]]

    const firstRowCount = Math.floor(recipes.length / 3)+1
    const secondRowCount = Math.floor((recipes.length+1) / 3)-1;

    const row1 = recipes.slice(0, firstRowCount);
    const row2 = recipes.slice(firstRowCount, firstRowCount + secondRowCount);
    const row3 = recipes.slice(firstRowCount + secondRowCount);

    return [row1, row2, row3];
  };

  const [row1, row2, row3] = splitRecipes(recipes);

  return (
    <div className="public-recipes">
      <Navbar active="recipes"/>
      <Container
        title={
          <div className="title">
            <Cake2 size={16}/>
            <span>Recipes</span>
          </div>
        }
        footer={
          <div className='footer'>
            <button disabled={year === 2024} onClick={() => setYear(currentYear => currentYear + 1)}
                    className='next'>{year + 1}</button>
            <button onClick={() => setYear(currentYear => currentYear - 1)} className='previous'>{year - 1}</button>
          </div>
        }
      >
        <div className='year'>Recipes from: <span>{year}</span></div>
        <div className="rows">
          <div className="row">
            {row1.map((recipe) => (
              <div key={recipe.id} className="block"
                   onClick={() => navigate(`/${params.email}/recipe/${recipe.id}`)}>
                <img src={recipe.image?.image_url || '/no-image.jpg'} alt="recipe" className="image"/>
                <span className='name'>{recipe.name}</span>
              </div>
            ))}
          </div>

          {row2.length > 0 && (
            <>
              <div className='splitter'/>
              <div className="big">
                {row2.map((recipe) => (
                  <div key={recipe.id} className="block"
                       onClick={() => navigate(`/${params.email}/recipe/${recipe.id}`)}>
                    <img src={recipe.image?.image_url || '/no-image.jpg'} alt="recipe" className="image"/>
                    <span className='name'>{recipe.name}</span>
                  </div>
                ))}
              </div>
            </>
          )}

          {row3.length > 0 && (
            <>
              <div className='splitter'/>
              <div className="row">
                {row3.map((recipe) => (
                  <div key={recipe.id} className="block"
                       onClick={() => navigate(`/${params.email}/recipe/${recipe.id}`)}>
                    <img src={recipe.image?.image_url || '/no-image.jpg'} alt="recipe" className="image"/>
                    <span className='name'>{recipe.name}</span>
                  </div>
                ))}
              </div>
            </>
          )}

        </div>
      </Container>
    </div>
  );
};

export default PublicRecipes;
