import axios from "axios";
import appConfig from "../../config/applicationConfiguration";

export const Login = (formData, callback) => {
  const config = {
    url: '/oauth/token',
    method: "POST",
    data: {
      ...formData,
      grant_type: "password",
      client_id: "my_uid",
      client_secret: "my_secret"

    },
    skipInterceptor: true
  };
  const response = axios.request(config)
    .then((response) => callback(response))
    .catch((error) => callback(error))
};

export const CurrentUser = (callback) => {
  const config = {
    url: '/users/current_user'
  }
  const response = axios.request(config)
    .then(response => {
      localStorage.setItem('userName', response.data.name);
      localStorage.setItem('role', response.data.role)
      localStorage.setItem('profilePicture', response.data.image_url)
      callback(response.data)
    })
}

export const Logout = (callback) => {
  const config = {
    url: '/users/sign_out',
    method: "DELETE",
  };
  const response = axios.request(config)
    .then(response => {
      localStorage.removeItem("authToken");
      localStorage.removeItem("userName");
      localStorage.removeItem("role");
      localStorage.removeItem('profilePicture')
      callback(response)
    })
    .catch((error) => callback(error.response));
};