import * as actions from "./RecipesActions.js";
import {loadTags} from "../tags/TagsActions";
import Container from "../../components/container/Container.js";
import DropZone from "../../components/form/dropzone/DropZone";
import Input from "../../components/form/input/Input";
import Textarea from "../../components/form/textarea/Textarea";
import {Cake2} from "react-bootstrap-icons";
import {useFormik} from "formik";
import * as yup from "yup";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import Select from "../../components/form/select/Select";
import {loadCategories} from "../categories/CategoriesActions";
import IngredientsTable from "./IngredientsTable";
import Navbar from "../../components/navbar/Navbar";

const Recipe = () => {
  const [tagOptions, setTagOptions] = useState([]);
  const [categoryOptions, setCategoryOption] = useState([]);

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    loadTags({all_records: true}, data => {
      setTagOptions(data.tags.map((tag) => ({value: tag.id, label: tag.name})));
    });
    loadCategories({all_records: true}, data => {
      setCategoryOption(data.categories.map((category) => ({value: category.id, label: category.name})));
    });
  }, []);

  const navigate = useNavigate();
  const params = useParams();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {

      let formData = new FormData();
      values.tags = values.tags?.map((tag) => (tag.id))
      values.recipe_ingredients_attributes = values.recipe_ingredients_attributes?.map((recipe_ingredient) => ({id: recipe_ingredient.id, ingredient_id: recipe_ingredient.ingredient.id, amount: recipe_ingredient.amount, _destroy: recipe_ingredient._destroy}))
      saveRecipe({...values, photo_id: values.image?.id});
    },

    validationSchema: yup.object({
      name: yup.string().required("Name is required"),
      description: yup.string().required("Description is required"),
      preparation_time: yup.number()
        .required("Preparation time is required")
        .positive("Preparation time should be positive"),
      category: yup.object().required("Category is required"),
    }),
    validateOnChange: false,
  });

  const saveRecipe = (values) => {
    actions.saveRecipe(
      {
        ...values,
        tag_ids: values.tags,
        category_id: values.category.id,
      },
      () => navigate("/recipes")
    );
  }

  useEffect(() => {
    if (params.id) {
      actions.loadRecipe(params.id, (data) => setInitialValues(data));
    }
  }, [params.id]);

  return (
    <>
      <Navbar active="recipes"/>
      <form onSubmit={formik.handleSubmit}>
        <Container
          title={
            <div className="title">
              <Cake2 size={16}/>
              <span>Recipes</span>
              <span>{`>`}</span>
              {formik.values.id ? (
                <span>{formik.initialValues.name}</span>
              ) : (
                <span>New</span>
              )}
            </div>
          }
          onSave={() => formik.submitForm()}
        >
          <Input
            required
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
          />
          <Textarea
            required={true}
            name="description"
            label="Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.errors.description}
          />
          <Input
            required
            type="number"
            name="preparation_time"
            label="Preparation time"
            short
            value={formik.values.preparation_time}
            onChange={formik.handleChange}
            error={formik.errors.preparation_time}
          />
          <Select
            required
            name="category"
            label="Category"
            options={categoryOptions}
            onChange={(selectedOption) => formik.setFieldValue("category", {id: selectedOption.value, name: selectedOption.label})}
            value={{value: formik.values.category?.id, label: formik.values.category?.name}}
            error={formik.errors.category}
          />
          <Select
            isMulti
            name="tags"
            label="Tag"
            options={tagOptions}
            onChange={(selectedOptions) => formik.setFieldValue("tags", selectedOptions.map(option => ({id: option.value, name: option.label})))}
            value={formik.values.tags?.map(tag => ({value: tag.id, label: tag.name}))}
          />
          <IngredientsTable
            ingredients={formik.values.recipe_ingredients_attributes}
            onChange={(selectedOptions) => formik.setFieldValue("recipe_ingredients_attributes", selectedOptions)}/>
          <DropZone
            image={formik.values.image}
            label="Photo"
            variant="big"
            onChange={(photo) => formik.setFieldValue("image", photo)}
          />
        </Container>
      </form>
    </>
  );
};

export default Recipe;
