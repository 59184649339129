import axios from "axios";

export const saveSubpage = (formData, callback) => {
  const config = {
    url: formData.id ? `/subpages/${formData.id}` : "/subpages",
    method: formData.id ? "PUT" : "POST",
    data: {subpage: formData},
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};

export const reorderSubpages = (formData, callback) => {
  const config = {
    url: "/subpages/update_order",
    method: "PUT",
    data: {subpage: {list: formData}},
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};


export const loadSubpage = (id, callback) => {
  const config = {
    url: `/subpages/${id}`,
    data: {
      subpage: { id: id },
    },
  };
  axios.request(config)
    .then((response) => callback(response.data))
};

export const loadSubpages = (callback) => {
  const config = {
    url: `/subpages/`,
  };
  axios.request(config)
    .then((response) => callback(response.data))
};

export const deleteSubpage = (id, callback) => {
  const config = {
    url: `/subpages/${id}`,
    method: "DELETE",
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};
