import axios from "axios";

export const loadIngredients = (page, callback) => {
  const config = {
    url: "/ingredients",
    params: page
  };
  axios.request(config)
    .then((response) => callback(response.data))
};
export const loadIngredient = (id, callback) => {
  const config = {
    url: `/ingredients/${id}`,
    data: {
      ingredient: {id: id},
    },
  };
  axios.request(config)
    .then((response) => callback(response.data))
};

export const saveIngredient = (formData, callback) => {
  const config = {
    url: formData.id ? `/ingredients/${formData.id}` : "/ingredients",
    method: formData.id ? "PUT" : "POST",
    data: {ingredient: formData},
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};

export const deleteIngredient = (id, callback) => {
  const config = {
    url: `/ingredients/${id}`,
    method: "DELETE",
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};
