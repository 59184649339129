import * as actions from "./UsersActions.js";
import Container from "../../../components/container/Container.js";
import DropZone from "../../../components/form/dropzone/DropZone";
import Input from "../../../components/form/input/Input";
import {Person} from "react-bootstrap-icons";
import {useFormik} from "formik";
import * as yup from "yup";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import Select from "../../../components/form/select/Select";
import {CurrentUser} from "../../login/LoginActions";


const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required").email("Email must be of correct format"),
  password: yup.string().when(['id', 'change_password'], {
    is: (id, change_password) => !id || change_password,
    then: (password) => password.required("Password is required").min(8, "Password must be at least 8 characters"),
    otherwise: (password) => password.nullable()
  }),
  confirm_password: yup.string().when(['id', 'change_password'], {
    is: (id, change_password) => !id || change_password,
    then: (password) => password.required("Confirm password is required").oneOf([yup.ref('password'), null], "Passwords must match"),
    otherwise: (password) => password.nullable()
  }),
  role: yup.string().required("Role is required")
});

const User = () => {
  const [initialValues, setInitialValues] = useState({});

  const roleOptions = [
    {value: "user", label: "User"},
    {value: "admin", label: "Admin"},
  ]
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      actions.loadUser(params.id, (data) => {
        setInitialValues({
          ...data,
          change_password: false,
        })
      });
    }
  }, [params.id]);



  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      saveUser({...values, photo_id: values.image?.id});
    },
    validateOnChange: false,
  });

  const saveUser = (values) => {
    actions.saveUser(
      values,
      () => CurrentUser(() => {
        navigate('/')
      })
    );
  }

  return (
    <>
      <Navbar content='admin' active="users"/>
      <form onSubmit={formik.handleSubmit}>
        <Container
          className="spacing"
          title={
            <div className="title">
              <Person size={16}/>
              <span>Users</span>
              <span>{`>`}</span>
              {formik.values.id ? (
                <span>{formik.initialValues.name}</span>
              ) : (
                <span>New</span>
              )}
            </div>
          }
          onSave={() => formik.submitForm()}
        >
          <Input
            required
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
          />
          <Input
            name="surname"
            label="Surname"
            value={formik.values.surname}
            onChange={formik.handleChange}
          />
          <Input
            required
            type="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
          />
          {!formik.initialValues.id && (
            <>
              <Input
                required
                type="password"
                name="password"
                label="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.errors.password}
              />
              <Input
                required
                type="password"
                name="confirm_password"
                label="Confirm password"
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
                error={formik.errors.confirm_password}
              />
            </>
          )}

          <Select
            required={true}
            name="role"
            label="Role"
            options={roleOptions}
            onChange={(e) => formik.setFieldValue("role", e.value)}
            value={{value: formik.values.role, label: formik.values.role}}
            error={formik.errors.role}
          />
          <DropZone
            image={formik.values.image}
            label="Profile picture"
            onChange={(photo) => formik.setFieldValue("image", photo)}
          />
          {formik.initialValues.id && (
            <>
              <Input
                type="checkbox"
                name="change_password"
                label="Change password"
                checked={formik.values.change_password}
                onChange={formik.handleChange}
              />
              {formik.values.change_password && (
                <>
                  <Input
                    required
                    type="password"
                    name="password"
                    label="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.errors.password}
                  />
                  <Input
                    required
                    type="password"
                    name="confirm_password"
                    label="Confirm password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    error={formik.errors.confirm_password}
                  />
                </>
              )}
            </>
          )}
        </Container>
      </form>
    </>
  );
};

export default User;