import Container from "../../components/container/Container";
import {Cake2, PencilSquare, QuestionCircleFill, Trash} from "react-bootstrap-icons";
import Table from "../../components/table/Table.js";
import * as actions from "./RecipesActions.js";
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router";
import Modal from "../../components/modal/Modal.js";
import Button from "../../components/button/Button.js";
import {Link} from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Select, {components} from "react-select";
import "./Recipes.scss"
import {loadTags} from "../tags/TagsActions";
import {loadCategories} from "../categories/CategoriesActions";
import {loadIngredients} from "../ingredients/IngredientsActions";
import {useLocation} from 'react-router-dom';

const PreparationTimeOptions = [
  {value: {}, label: ""},
  {value: {min_preparation_time: 0, max_preparation_time: 10}, label: "Under 10 min"},
  {value: {min_preparation_time: 10, max_preparation_time: 30}, label: "10-30 min"},
  {value: {min_preparation_time: 30, max_preparation_time: 60}, label: "30-60 min"},
  {value: {min_preparation_time: 60, max_preparation_time: 90}, label: "60-90 min"},
  {value: {min_preparation_time: 90}, label: "Over 90 min"},
]


const CheckboxOption = (props) => {
  return (
    <components.Option {...props}>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <span>{props.label}</span>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{
            accentColor: '#8577ec',
            cursor: 'pointer',
          }}
        />
      </div>
    </components.Option>
  );
};

const customSelectStyles = {
  option: (provided, {isSelected}) => ({
    ...provided,
    backgroundColor: isSelected ? '#ddd' : '#fff',
    color: '#333',
    '&:hover': {
      color: '#8577ec',
    },
  }),
}

const customMultiStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#fff',
    borderColor: state.isFocused ? '#aaa' : '#ddd',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#aaa',
    }
  }),

  option: (provided, {isSelected}) => ({
    ...provided,
    backgroundColor: isSelected ? '#fff' : '#fff',
    color: '#333',
    '&:hover': {
      color: '#8577ec',
    },
  }),

  multiValue: (styles) => ({
    ...styles,
    backgroundColor: '#d6d1f4',
    borderRadius: '7px',
    padding: "1px",
  }),

  multiValueRemove: (styles) => ({
    ...styles,
    color: '#555',
    ':hover': {
      backgroundColor: '#b0aae0',
      color: '#000',
      borderRadius: '7px',
    },
  }),
};


const Recipes = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const [recipeToDelete, setRecipeToDelete] = useState();
  const [recipes, setRecipes] = useState();
  const [tagOptions, setTagOptions] = useState([]);
  const [categoryOptions, setCategoryOption] = useState([]);
  const [ingredientOptions, setIngredientOptions] = useState([]);
  const [sortBy, setSortBy] = useState({id: "id", desc: true});
  const [pages, setPages] = useState(0)
  const [filters, setFilters] = useState({
    category_id: location.state?.id,
    ingredient_ids: [],
    tag_ids: [],
    preparation_time: null
  })

  useEffect(() => {
    loadTags({all_records: true}, data => {
      setTagOptions(data.tags.map((tag) => ({value: tag.id, label: tag.name})));
    });
    loadCategories({all_records: true}, data => {
      setCategoryOption([{id: null, label: null}, ...data.categories].map((category) => ({value: category.id, label: category.name})));
    });
    loadIngredients({all_records: true}, data => {
      setIngredientOptions(data.ingredients.map((ingredient) => ({value: ingredient.id, label: ingredient.name})));
    });
  }, []);

  const handleDelete = () => {
    const recipe = recipeToDelete.original
    actions.deleteRecipe(recipe.id, () => {
      setRecipes(
        recipes => recipes.filter((_, index) => index != recipeToDelete.id)
      )
      setRecipeToDelete()
    });
  };

  const columns = React.useMemo(() => [
    {
      Header: "Recipe",
      accessor: "name",
      sortType: "alphanumeric",
      Cell: (tableProps) => (
        <div className="col">
          {tableProps.row.original.image.image_url ? (
            <img
              src={tableProps.row.original.image.image_url}
              alt="icon"
              className="image"
            />
          ) : (
            <QuestionCircleFill className="no-image-icon" size={40}/>
          )}
          {tableProps.row.original.name}
        </div>
      ),
    },
    {
      Header: "Published",
      accessor: "created_at",
      Cell: (tableProps) => (
        <>{new Date(tableProps.row.original.created_at).toLocaleDateString('en-GB')}</>
      ),
    },
    {
      Header: "Options",
      Cell: (tableProps) => (
        <div className="options">
          <Link to={`/recipe/${tableProps.row.original.id}`}>
            <PencilSquare size={20}/>
          </Link>
          <a>
            <Trash
              onClick={() => setRecipeToDelete(tableProps.row)}
              size={20}
            />
          </a>
        </div>
      ),
      disableSortBy: true,
    },
  ]);


  useEffect(() => {
    actions.loadRecipes(
      {
        ...filters,
        sort: sortBy.id.concat(sortBy.desc ? " DESC" : " ASC")
      },
      (data) => {
        setRecipes(data.recipes)
        setPages(data.total_pages)
      }
    );
  }, [sortBy, filters]);
  return (
    <div className='recipes'>
      <Navbar active="recipes"/>
      <Modal
        isOpen={recipeToDelete}
        title={
          <div className="title">
            <span>Recipes</span>
            <span>{`>`}</span>
            <span>Delete {recipeToDelete?.original.name}</span>
          </div>
        }
        footer={
          <>
            <Button variant='secondary' onClick={() => setRecipeToDelete()}>
              Cancel
            </Button>
            <Button variant='danger' onClick={() => handleDelete()}>
              Delete
            </Button>
          </>
        }
      />
      <Container
        title={
          <div className="title">
            <Cake2 size={16}/>
            <span>Recipes</span>
          </div>
        }
        onAdd={() => navigate('/recipe')}
      >
        <div className='filters'>
          <Select
            defaultValue={location.state ? {value: location.state?.id, label: location.state?.label} : null}
            className="filter"
            placeholder="Categories"
            options={categoryOptions}
            styles={customSelectStyles}
            isClearable={false}
            onChange={(selectedCategory) => setFilters({...filters, category_id: selectedCategory.value})}
          />
          <Select
            isMulti={true}
            className="filter"
            placeholder="Ingredients"
            options={ingredientOptions}
            components={{Option: CheckboxOption}}
            hideSelectedOptions={false}
            styles={customMultiStyles}
            isClearable={false}
            onChange={(selectedIngredients) => setFilters({
              ...filters,
              ingredient_ids: selectedIngredients.map(ingredient => (ingredient.value))
            })}
          />
          <Select
            isMulti
            className="filter"
            placeholder="Tags"
            options={tagOptions}
            components={{Option: CheckboxOption}}
            hideSelectedOptions={false}
            styles={customMultiStyles}
            isClearable={false}
            onChange={(selectedTags) => setFilters({...filters, tag_ids: selectedTags.map(tag => (tag.value))})}
          />
          <Select
            className="filter"
            placeholder="Preparation time"
            options={PreparationTimeOptions}
            isClearable={false}
            styles={customSelectStyles}
            onChange={(selectedPreparationTime) => setFilters({
              ...filters,
              min_preparation_time: selectedPreparationTime.value.min_preparation_time,
              max_preparation_time: selectedPreparationTime.value.max_preparation_time
            })}
          />
        </div>
        {recipes && (
          <Table
            columns={columns}
            data={recipes}
            onSortChange={setSortBy}
            onPageChange={(e) => {
              actions.loadRecipes({
                ...filters,
                sort: sortBy.id.concat(sortBy.desc ? " DESC" : " ASC"),
                page: e.pageIndex + 1
              }, (data) => setRecipes(data.recipes));
            }}
            pageCount={pages}
          />
        )}
      </Container>
    </div>
  );
};
export default Recipes;
