import {useTable, useSortBy, usePagination} from "react-table";
import "./Table.scss";
import classNames from "classnames";
import {CaretUpFill, CaretDownFill} from "react-bootstrap-icons";
import {useEffect} from "react";

const Table = ({data, columns, striped, onSortChange, onPageChange, pageCount}) => {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    state: {sortBy, pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      autoResetSortBy: false,
      manualPagination: true,
      pageCount: pageCount,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (sortBy.length > 0) {
      onSortChange(sortBy[0]);
    }
  }, [sortBy]);

  useEffect(() => {
    if (onPageChange) {
      onPageChange({pageIndex, pageSize});
    }
  }, [pageIndex, pageSize]);


  return (
    <div className='table'>
      <table {...getTableProps()}>
        <thead className="table-head">
        {headerGroups?.map((headerGroup) => (
          <tr
            className={classNames({striped}, "header")}
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <div>
                  {column.render("Header")}
                  <span className='sorting'>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <>
                            <CaretUpFill size={12}/>
                            <CaretDownFill className="active" size={12}/>
                          </>
                        ) : (
                          <>
                            <CaretUpFill className="active" size={12}/>
                            <CaretDownFill size={12}/>
                          </>
                        )
                      ) : (
                        <>
                          <CaretUpFill size={12}/>
                          <CaretDownFill size={12}/>
                        </>
                      )}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {page?.map((row) => {
          prepareRow(row);
          return (
            <tr className={classNames({striped})} {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
        </tbody>
      </table>
      {onPageChange && (
        <div className="pagination">
          <button
            onClick={() => gotoPage(pageIndex - 1)}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>
          {pageOptions.length > 4 ? (
            pageIndex < pageOptions.length / 2 ? (
              <>
                {pageOptions.map((_, index) => (
                  index < pageOptions.length / 2 && (
                    <button onClick={() => gotoPage(index)}
                            className={classNames({"currentPage": pageIndex === index})}>{index + 1}</button>
                  )
                ))}
                <button>...</button>
                <button onClick={() => gotoPage(pageOptions.length - 1)}
                        className={classNames({"currentPage": pageIndex === 5})}>{pageOptions.length}</button>
              </>
            ) : (
              <>

                <button onClick={() => gotoPage(0)}
                        className={classNames({"currentPage": pageIndex === 1})}>1
                </button>
                <button>...</button>
                {pageOptions.map((_, index) => (
                  index >= pageOptions.length / 2 && (
                    <button onClick={() => gotoPage(index)}
                            className={classNames({"currentPage": pageIndex === index})}>{index + 1}</button>
                  )
                ))}

              </>
            )
          ) : (
            pageOptions.map((_, index) => (
              <button onClick={() => gotoPage(index)}
                      className={classNames({"currentPage": pageIndex === index})}>{index + 1}</button>
            ))
          )}
          <button
            onClick={() => gotoPage(pageIndex + 1)}
            disabled={!canNextPage}
          >
            {'>'}
          </button>
        </div>
      )}
    </div>
  );
};

export default Table;