import * as actions from "./RecipesActions.js";
import Container from "../../../components/container/Container.js";
import {Cake2} from "react-bootstrap-icons";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import moment from "moment";
import "./PublicRecipe.scss"

const PublicRecipe = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [recipe, setRecipe] = useState()


  useEffect(() => {
    if (params.id) {
      actions.loadRecipe(params, (data) => setRecipe(data));
    }
  }, [params]);

  return (
    <div className='public-recipe'>
      <Navbar active="recipes"/>
      <Container
        title={
          <div className="title">
            <Cake2 size={16}/>
            <span>Recipes</span>
            <span>{`>`}</span>
            <span>{recipe?.name}</span>
          </div>
        }
      >
        {recipe && (
          <div className='recipe'>
            <div className='header'>
              <h2>{recipe.name}</h2>
            </div>
            <div className='body'>
              <div className='author'>
                {recipe.author.image?.image_url && (
                  <img className='profile' alt='profile picture' src={recipe.author.image.image_url}/>
                )}
                <span>BY {recipe.author.name} {recipe.author?.surname}</span>
                <span>{moment(recipe.created_at).format('MMMM D, YYYY')}</span>
                {recipe.image?.image_url && (
                  <img className='recipe' alt='recipe image' src={recipe.image.image_url}/>
                )}
              </div>
              <div className='preparation'>
                <span><b>Preparation time:</b> {recipe.preparation_time}min</span>
                {recipe.recipe_ingredients_attributes.length > 0 && (
                  <span className='ingredients'>
                    <b>Ingredients:</b>
                    <ul>
                    {recipe.recipe_ingredients_attributes.map(ingredient => (
                      <li>
                        {ingredient.ingredient.name}: {ingredient.amount} ({ingredient.ingredient.unit})
                      </li>
                    ))}
                    </ul>
                  </span>
                )}
                <span>
                  <b>Preparation:</b>
                  <span className='description'>
                    {recipe.description}
                  </span>
                </span>
              </div>
            </div>
          </div>
        )}

      </Container>
    </div>
  );
};

export default PublicRecipe;