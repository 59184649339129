import {Doughnut} from 'react-chartjs-2';
import {Chart as ChartJS, Title, Tooltip, Legend, ArcElement} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {useState} from "react";
import {useNavigate} from "react-router-dom";

ChartJS.register(Title, Tooltip, Legend, ArcElement, ChartDataLabels);


const DoughnutChart = ({chartData, amountOfCategories}) => {
  const navigate = useNavigate()

  const options = {
    hoverOffset: 4,
    cutout: '70%',
    borderWidth: 0,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.label + ': ' + tooltipItem.raw;
          }
        }
      },
      datalabels: {
        color: '#fff',
        font: {
          size: 14,
          weight: 'bold',
        },
        formatter: (value, context) => {
          return value;
        },
      }
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20
      }
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].index;
        const label = chartData.labels[elementIndex];
        const id = chartData.ids[elementIndex]
        navigate(`/recipes`,{state:{id: id, label: label}});
      }
    },
  };

  return (
    <div className='doughnut'>
      <div>
        <span>{amountOfCategories}</span>
        <span>Recipes</span>
      </div>
      <div>
        <Doughnut data={chartData} options={options}/>
      </div>
    </div>
  )
}

export default DoughnutChart