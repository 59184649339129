import Table from "../../table/Table";
import "./FormTable.scss"

const FormTable = ({data, columns, required, label, onAdd}) => {


  return (
    <div className='form-table'>
      <div className="label">
        <span>
          {required && <span>*</span>}
          {label}
        </span>
      </div>
      <div className='table-container'>
        <Table columns={columns} data={data} striped border />
        <button onClick={onAdd} type='button'>+Add new</button>
      </div>
    </div>

  )
}

export default FormTable