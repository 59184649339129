import "./Dropdown.scss";
import { useState } from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {ReactComponent as arrowIcon} from "./arrow.svg";
import Icon from "../icons/Icon";

const Dropdown = ({children, title, border}) => {
  const [opened, setOpened] = useState(false);

  return (
    <div
      className={classNames('dropdown', { opened })}
      onClick={() => setOpened((state) => !state)}
    >
      <div className={classNames('menu', {border})}>
        {title}
        <Icon className='arrow' icon={arrowIcon} />
      </div>
      <div className="options">
        {children}
      </div>
    </div>
  );
};
export default Dropdown;