import "./Modal.scss";
import classNames from "classnames";

const Modal = ({isOpen, body, footer, title}) => {

  return (
    <div className={classNames("modal", {"opened": isOpen})}>
      <div className="header">
        <div>{title} </div>
      </div>
      <div className="body">{body}</div>
      <div className="footer">{footer}</div>
    </div>
  );
};
export default Modal;
