import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement } from 'chart.js';
import { CategoryScale, LinearScale } from 'chart.js';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, Title, Tooltip, Legend, BarElement);

const BarChart = ({ categories, colors, amountOfCategories, dateRange }) => {
  const [chartData, setChartData] = useState({ datasets: [] });
  const navigate = useNavigate();

  const generateMonthsInRange = (startDate, endDate) => {
    const months = [];
    let current = moment(startDate).startOf('month');
    const end = moment(endDate).endOf('month');

    while (current <= end) {
      months.push(current.format('MMM YYYY'));
      current.add(1, 'month');
    }
    return months;
  };

  useEffect(() => {
    const months = generateMonthsInRange(dateRange.start_date, dateRange.end_date);
    const categoryNames = [...new Set(categories.flat().map(item => item.name))];

    const datasets = categoryNames.map((categoryName, index) => {
      const categoryDataPerMonth = months.map((month) => {
        const formattedMonth = moment(month, 'MMM YYYY').format('MM-YYYY');
        const foundCategory = categories.flat().find(item => item.name === categoryName && item.date === formattedMonth);
        return foundCategory ? foundCategory.recipe_amount : 0;
      });

      return {
        label: categoryName,
        data: categoryDataPerMonth,
        backgroundColor: colors[index % colors.length],
      };
    });

    setChartData({
      labels: months,
      datasets: datasets,
    });
  }, [categories, colors, dateRange]);

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months'
        }
      },
      y: {
        ticks: {
          precision: 0
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.dataset.label + ': ' + tooltipItem.raw;
          }
        }
      },
      datalabels: {
        display: false,
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].datasetIndex;
        const label = chartData.datasets[elementIndex].label;
        const id = categories.flat().find(item => item.name === label)?.id;
        navigate(`/recipes`, { state: { id: id, label: label } });
      }
    }
  };

  return (
    <div className='barchart'>
      <div>
        <span>From: {dateRange?.start_date || `${new Date().getFullYear()}-01-01`} To: {dateRange?.end_date || `${new Date().getFullYear()}-12-31`}</span>
        <span>{amountOfCategories}</span>
        <span>Recipes</span>
      </div>
      <div>
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default BarChart;
