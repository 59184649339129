import "./Textarea.scss"
import classNames from "classnames";
import React from "react";

const Textarea = ({required, label, name, value, onChange, error}) => {

  return (
    <div className="textarea">
      <div className="label">
        <span>
          {required && <span>*</span>}
          {label}
        </span>
      </div>
      <div className="textarea-container">
        <textarea
          className={classNames("textarea-field", {error})}
          value={value}
          name={name}
          onChange={onChange}
          placeholder={error ? error : ''}
        />
      </div>
    </div>)
}

export default Textarea