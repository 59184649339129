import * as actions from "./CategoriesActions.js";
import Container from "../../components/container/Container.js";
import DropZone from "../../components/form/dropzone/DropZone";
import Input from "../../components/form/input/Input";
import {ListTask} from "react-bootstrap-icons";
import {useFormik} from "formik";
import * as yup from "yup";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";

const Category = () => {
  const [initialValues, setInitialValues] = useState({});

  const navigate = useNavigate();
  const params = useParams();
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      saveCategory({...values, photo_id: values.image?.id});
    },

    validationSchema: yup.object({
      name: yup.string().required("Name is required"),
    }),
    validateOnChange: false,

  });

  useEffect(() => {
    if (params.id) {
      actions.loadCategory(params.id, (data) => {
        setInitialValues({...data});
      });
    }
  }, [params.id]);

  const saveCategory = (values) => {
    actions.saveCategory(
      {id: values.id, name: values.name, photo_id: values.photo_id},
      () => navigate("/categories")
    );
  }

  return (
    <>
      <Navbar/>
      <form onSubmit={formik.handleSubmit}>
        <Container
          title={
            <div className="title">
              <ListTask size={16}/>
              <span>Categories</span>
              <span>{`>`}</span>
              {formik.values.id ? (
                <span>edit - {formik.initialValues.name}</span>
              ) : (
                <span>New</span>
              )}
            </div>
          }
          onSave={() => formik.submitForm()}
        >
          <Input
            required={true}
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
          />
          <DropZone
            image={formik.values.image}
            label="Photo"
            onChange={(photo) => formik.setFieldValue("image", photo)}
          />
        </Container>
      </form>
    </>
  );
};

export default Category;
