import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { client } from "scrumship-client";
import "./App.scss";
import appConfig from "./config/applicationConfiguration";

axios.defaults.baseURL = appConfig.apiUrl;
client.log("lXruWYU");
axios.defaults.headers['Accept'] = 'application/json';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
