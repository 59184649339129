import axios from "axios";

export const loadCategories = (page, callback) => {
  const config = {
    url: "/categories",
    params: page
  };
  axios.request(config)
    .then((response) => callback(response.data))
};
export const loadCategory = (id, callback) => {
  const config = {
    url: `/categories/${id}`,
    data: {
      category: {id: id},
    },
  };
  axios.request(config)
    .then((response) => callback(response.data))
};

export const saveCategory = (formData, callback) => {
  const config = {
    url: formData.id ? `/categories/${formData.id}` : "/categories",
    method: formData.id ? "PUT" : "POST",
    data: {category: formData},
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};

export const deleteCategory = (id, callback) => {
  const config = {
    url: `/categories/${id}`,
    method: "DELETE",
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};
