import axios from "axios";

export const savePhoto = (formData, callback) => {
  const config = {
    url: "/photos",
    method: "POST",
    data: formData,
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};
