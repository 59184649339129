import Navbar from "../../components/navbar/Navbar";
import Container from "../../components/container/Container";
import {PieChartFill} from "react-bootstrap-icons";
import "./Reports.scss";
import * as actions from './ReportsActions';
import {useEffect, useState} from "react";
import DoughnutChart from "./Doughnut";
import DataTable from "./DataTable";
import BarChart from "./BarChart";

const colors = [
  '#26547C', '#EF476F', '#FFD166', '#06D6A0', '#00E8FC',
  '#CA3CFF', '#B4A0E5', '#832232', '#EAF27C', '#391463',
  '#4D9DE0', '#E15554', '#E1BC29', '#3BB273', '#7768AE',
  '#F1C8DB', '#B88C9E', '#704C5E', '#88A09E', '#558B6E',
];

const Reports = () => {
  const [categories, setCategories] = useState([]);
  const [amountOfCategories, setAmountOfCategories] = useState(0);
  const [dateRange, setDateRange] = useState();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{
      label: 'Recipes in category',
      data: [],
      backgroundColor: [],
    }],
    ids: []
  });

  useEffect(() => {
    actions.loadReports(dateRange, data => {
      setCategories(data)

      const flattenedData = data.flat();

      const labels = [];
      const chartDataPoints = [];
      const chartDataPointIds = [];

      let totalRecipeCount = 0;

      flattenedData.forEach((categoryData) => {
        const existingIndex = labels.findIndex(label => label === categoryData.name);

        if (existingIndex === -1) {
          labels.push(categoryData.name);
          chartDataPoints.push(categoryData.recipe_amount);
          chartDataPointIds.push(categoryData.id);
        } else {
          chartDataPoints[existingIndex] += categoryData.recipe_amount;
        }

        totalRecipeCount += categoryData.recipe_amount;
      });

      setChartData({
        labels: labels,
        datasets: [{
          label: 'Recipes reports',
          data: chartDataPoints,
          backgroundColor: colors.slice(0, chartDataPoints.length),
        }],
        ids: chartDataPointIds
      });

      setAmountOfCategories(totalRecipeCount);
    });
  }, [dateRange]);

  return (
    <div className='reports'>
      <Navbar active="reports"/>
      <Container
        onDateFilter={setDateRange}
        title={
          <div className="title">
            <PieChartFill size={16}/>
            <span>Reports</span>
          </div>
        }
      >
        <div className='grid'>
          <DoughnutChart amountOfCategories={amountOfCategories} chartData={chartData}/>
          <DataTable amountOfCategories={amountOfCategories} chartData={chartData} categories={categories}/>
          <BarChart amountOfCategories={amountOfCategories}
                    categories={categories}
                    colors={colors}
                    dateRange={dateRange || {start_date: '2024-01-01', end_date: '2024-12-31'}}/>
        </div>
      </Container>
    </div>
  )
}

export default Reports;