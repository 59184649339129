import {useNavigate} from "react-router";
import React, {useEffect, useState} from "react";
import * as actions from "./SearchActions";
import {ListTask, PencilSquare, QuestionCircleFill, Trash} from "react-bootstrap-icons";
import {Link, useParams} from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Modal from "../../components/modal/Modal";
import Button from "../../components/button/Button";
import Container from "../../components/container/Container";
import Table from "../../components/table/Table";
import {searchAction} from "../../components/search/SearchActions";

const singularVersions = {
  "categories": "Category",
  "ingredients": "Ingredient",
  "tags": "Tag",
  "recipes": "Recipe",
  "articles": "Article"
}
const SearchPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [itemToDelete, setItemToDelete] = useState();
  const [items, setItems] = useState();

  const handleDelete = () => {
    if (!itemToDelete.has_recipes) {
      actions.deleteItem(itemToDelete.id, params.item, () => {
        setItems(
          items => items.filter((category) => category.id != itemToDelete.id)
        )
        setItemToDelete()
      });
    }
  };

  useEffect(() => {
    actions.searchItems(
      params.query,
      params.item,
        data => {setItems(data)});
  }, [params]);

  const columns = React.useMemo(() => [
    {
      Header: singularVersions[params.item],
      accessor: "name",
      disableSortBy: true,
      Cell: (tableProps) => (
        <div className="col">
          {tableProps.row.original.image && (
            tableProps.row.original.image?.image_url ? (
              <img
                src={tableProps.row.original.image.image_url}
                alt="icon"
                className="image"
              />
            ) : (
              <QuestionCircleFill className="no-image-icon" size={40}/>
            )
          )}

          {tableProps.row.original.name}
        </div>
      ),
    },
    {
      Header: "Options",
      disableSortBy: true,
      Cell: (tableProps) => (
        <div className="options">
          <Link to={`/${singularVersions[params.item]}/${tableProps.row.original.id}`}>
            <PencilSquare size={20}/>
          </Link>
          <Trash
            onClick={() => setItemToDelete(tableProps.row.original)}
            size={20}
          />
        </div>
      ),
    },
  ]);

  return (
    <>
      <Navbar/>
      <Modal
        isOpen={itemToDelete}
        title={
          <div className="title">
            <span>{params.item}</span>
            <span>{`>`}</span>
            <span>Delete {itemToDelete?.name}</span>
          </div>
        }
        footer={
          <>
            <Button variant='secondary' onClick={() => setItemToDelete()}>
              Cancel
            </Button>
            <Button disabled={itemToDelete?.has_recipes} variant='danger' onClick={() => handleDelete()}>
              Delete
            </Button>
          </>
        }
      />
      <Container
        title={
          <div className="title">
            <ListTask size={16}/>

            <span>Search: </span>
            <span>{params.query}</span>
          </div>
        }
      >
        {items && (
          <Table
            columns={columns}
            data={items}
          />
        )}
      </Container>
    </>
  );
}

export default SearchPage