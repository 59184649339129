import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {DateRangePicker} from 'react-dates';
import {useEffect, useState} from "react";
import moment from 'moment';
import "./DatePicker.scss";
import Button from "../button/Button";
import {Funnel} from "react-bootstrap-icons";

const DatePicker = ({onCancel, onSave}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <div className='datepicker'>
      <div className='header'>
        <Funnel/> Filters
      </div>
      <DateRangePicker
        startDate={startDate}
        startDateId="start_date_id"
        endDate={endDate}
        endDateId="end_date_id"
        onDatesChange={({startDate, endDate}) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        numberOfMonths={1}
        isOutsideRange={() => false}
        displayFormat="YYYY-MM-DD"
        reopenPickerOnClearDates={true}
        block={true}
        minimumNights={0}
      />
      <div className='footer'>
        <Button onClick={() =>
          onSave({
            start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
          })
        } variant='primary'>Save</Button>
        <Button onClick={onCancel} variant='secondary'>Cancel</Button>
      </div>
    </div>
  )
}

export default DatePicker