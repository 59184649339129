import JoditEditor from "jodit-react";
import React, {useMemo, useRef, useState} from "react";
import "./Wysiwyg.scss"
import classNames from "classnames";

const Wysiwyg = ({required, label, name, value, error, onChange}) => {
  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: error || "Text",
      toolbar: true,
      buttons: [
        'bold', 'italic', 'underline', 'strikethrough', 'brush', 'subscript', 'superscript', 'eraser',
        'ul', 'ol',
        'font', 'fontsize', 'paragraph',
        'align',
        'image', 'link', 'file',
        'hr', 'table',
        'undo', 'redo',
      ],
      uploader: {
        insertImageAsBase64URI: true,
      },
    }),
    [error]
  );

  return (
    <div className={classNames("wysiwyg", {error})}>
      <div className="label">
        <span>
          {required && <span>*</span>}
          {label}
        </span>
      </div>
      <JoditEditor
        name={name}
        ref={editor}
        value={value}
        config={config}
        onBlur={onChange}
        onChange={onChange}
      />
    </div>
  )
}

export default Wysiwyg