import Container from "../../../components/container/Container";
import {Newspaper} from "react-bootstrap-icons";
import * as actions from "./ArticlesActions.js";
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router";
import Navbar from "../../../components/navbar/Navbar";
import {useParams} from "react-router-dom";
import "./PublicArticles.scss";

const PublicArticles = () => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [year, setYear] = useState(2024)

  const params = useParams();

  useEffect(() => {
    actions.loadArticles(
      {
        year: year,
        email: params.email,
      },
      (data) => {
        setArticles(data);
      }
    );
  }, [params.email, year]);

  const splitArticles = (articles) => {
    if (!articles || articles.length === 0) return [[], [], []];
    if (articles.length === 1) return [articles,[],[]]

    const firstRowCount = Math.floor(articles.length / 3)+1
    const secondRowCount = Math.floor((articles.length+1) / 3)-1;

    const row1 = articles.slice(0, firstRowCount);
    const row2 = articles.slice(firstRowCount, firstRowCount + secondRowCount);
    const row3 = articles.slice(firstRowCount + secondRowCount);

    return [row1, row2, row3];
  };

  const [row1, row2, row3] = splitArticles(articles);

  return (
    <div className="public-articles">
      <Navbar active="articles"/>
      <Container
        title={
          <div className="title">
            <Newspaper size={16}/>
            <span>All Articles</span>
          </div>
        }
        footer={
          <div className='footer'>
            <button disabled={year === 2024} onClick={() => setYear(currentYear => currentYear + 1)}
                    className='next'>{year + 1}</button>
            <button onClick={() => setYear(currentYear => currentYear - 1)} className='previous'>{year - 1}</button>
          </div>
        }
      >
        <div className='year'>Articles from: <span>{year}</span></div>
        <div className="rows">
          <div className="row">
            {row1.map((article) => (
              <div key={article.id} className="block"
                   onClick={() => navigate(`/${params.email}/article/${article.id}`)}>
                <img src={article.image?.image_url || '/no-image.jpg'} alt="article" className="image"/>
                <span className='name'>{article.name}</span>
              </div>
            ))}
          </div>

          {row2.length > 0 && (
            <>
              <div className='splitter'/>
              <div className="big">
                {row2.map((article) => (
                  <div key={article.id} className="block"
                       onClick={() => navigate(`/${params.email}/article/${article.id}`)}>
                    <img src={article.image?.image_url || '/no-image.jpg'} alt="article" className="image"/>
                    <span className='name'>{article.name}</span>
                  </div>
                ))}
              </div>
            </>
          )}

          {row3.length > 0 && (
            <>
              <div className='splitter'/>
              <div className="row">
                {row3.map((article) => (
                  <div key={article.id} className="block"
                       onClick={() => navigate(`/${params.email}/article/${article.id}`)}>
                    <img src={article.image?.image_url || '/no-image.jpg'} alt="article" className="image"/>
                    <span className='name'>{article.name}</span>
                  </div>
                ))}
              </div>
            </>
          )
          }
        </div>
      </Container>
    </div>
  );
};

export default PublicArticles;
