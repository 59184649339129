import React, {useEffect} from 'react';
import "./Select.scss";
import classNames from "classnames";
import Select from "react-select";

const SelectInput = ({options, name, required, label, onChange, value, error, isMulti}) => {
  return (
    <div className="select">
      <div className="label">
        <span>
          {required && <span>*</span>}
          {label}
        </span>
      </div>
      <Select
        value={value?.value || value?.length ? value : null}
        isMulti={isMulti}
        name={name}
        onChange={onChange}
        options={options}
        className={classNames("select-field", {error})}
        classNamePrefix="select"
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: error ? 'red' : '#d0d0d0',
            backgroundColor: error ? "#fee" : "white"
          }),
          multiValue: (styles, {data}) => {
            return {
              ...styles,
              backgroundColor: '#d6d1f4',
              borderRadius: '7px',
              padding: "4px",
            };
          },
          placeholder: (defaultStyles) => {
            if (error) {
              return {
                ...defaultStyles,
                color: '#f55',
              }
            }
            return {
              ...defaultStyles,
            }
          }
        }}

        placeholder={error ? error : "Select"}
      />
    </div>
  );
};

export default SelectInput;