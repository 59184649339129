import Container from "../../components/container/Container";
import {Egg, PencilSquare, QuestionCircleFill, Trash} from "react-bootstrap-icons";
import Table from "../../components/table/Table.js";
import * as actions from "./IngredientsActions.js";
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router";
import Modal from "../../components/modal/Modal.js";
import Button from "../../components/button/Button.js";
import {Link} from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";

const Ingredients = () => {
  const navigate = useNavigate();
  const [ingredientToDelete, setIngredientToDelete] = useState();
  const [ingredients, setIngredients] = useState();
  const [pages, setPages] = useState();
  const handleDelete = () => {
    actions.deleteIngredient(ingredientToDelete.id, () => {
      setIngredients(
        ingredients => ingredients.filter((ingredient) => ingredient.id != ingredientToDelete.id)
      )
      setIngredientToDelete()
    });
  };

  const columns = React.useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      disableSortBy: true,
      Cell: (tableProps) => (
        <div className="col">
          {tableProps.row.original.image.image_url ? (
            <img
              src={tableProps.row.original.image.image_url}
              alt="icon"
              className="image"
            />
          ) : (
            <QuestionCircleFill className="no-image-icon" size={40}/>
          )}
          {tableProps.row.original.name}
        </div>
      ),
    },
    {
      Header: "Unit",
      accessor: "unit",
      disableSortBy: true,
    },
    {
      Header: "Options",
      disableSortBy: true,
      Cell: (tableProps) => (
        <div className="options">
          <Link to={`/ingredient/${tableProps.row.original.id}`}>
            <PencilSquare size={20}/>
          </Link>
          <a>
            <Trash
              onClick={() => setIngredientToDelete(tableProps.row.original)}
              size={20}
            />
          </a>
        </div>
      ),
    },
  ]);


  useEffect(() => {
    actions.loadIngredients({},(data) => {
      setIngredients(data.ingredients)
      setPages(data.total_pages)
    });
  }, []);
  return (
    <>
      <Navbar />
      <Modal
        isOpen={ingredientToDelete}
        title={
          <div className="title">
            <span>Ingredients</span>
            <span>{`>`}</span>
            <span>Delete {ingredientToDelete?.name}</span>
          </div>
        }
        footer={
          <>
            <Button variant='secondary' onClick={() => setIngredientToDelete()}>
              Cancel
            </Button>
            <Button variant='danger' onClick={() => handleDelete()}>
              Delete
            </Button>
          </>
        }
      />
      <Container
        title={
          <div className="title">
            <Egg size={16}/>

            <span>Ingredients</span>
          </div>
        }
        onAdd={() => navigate('/ingredient')}
      >
        {ingredients && (
          <Table
            columns={columns}
            data={ingredients}
            onPageChange={(page) => {actions.loadIngredients({page: page.pageIndex+1}, (data) => setIngredients(data.ingredients));}}
            pageCount={pages}
          />
        )}
      </Container>
    </>
  );
};
export default Ingredients;