import "./Input.scss";
import classNames from "classnames";

const Input = ({required, label, name, value, onChange, error, type='text', short, step}) => {
  return (
    <div className="input">
      <div className="label">
        <span>
          {required && <span>*</span>}
          {label}
        </span>
      </div>
      <div className="input-container">
        <input
          type={type}
          name={name}
          value={value}
          step={step}
          onChange={onChange}
          className={classNames("input-field", {error, short})}
          placeholder={type !== "number" && error ? error : ''}
        />
        {type === "number" && (<><span>min</span><span className='number-field-error'>{error}</span></>)}
      </div>

    </div>
  );
};

export default Input;
