import "./Navbar.scss";
import Dropdown from "../dropdown/Dropdown.js";
import {Link, useParams} from "react-router-dom";
import {Cake2, Files, Newspaper, People, PersonCircle, PieChartFill, Search} from "react-bootstrap-icons";
import {Logout} from "../../pages/login/LoginActions";
import {useNavigate} from "react-router-dom";
import SearchBar from "../search/Search";
import {useEffect, useState} from "react";
import * as actions from './NavbarActions'

const Navbar = ({content = 'recipes', active}) => {
  const navigate = useNavigate()
  const role = localStorage.getItem('role')
  const userName = localStorage.getItem('userName')
  const profilePicture = localStorage.getItem('profilePicture')
  const params = useParams()
  const [subpages, setSubpages] = useState()

  useEffect(() => {
    if (params.email) {
      actions.loadSubpages(params, (data) => setSubpages(data))
    }
  }, [params]);

  return (
    <div className="navbar">
      <div className='items'>
        {subpages ? (
          <div className='links'>
            <Link className={`link ${active === "recipes" ? "active" : ''}`} to={`/${params.email}/recipes`}>
              <Cake2 size={18}/>
              Recipes
            </Link>
            <Link className={`link ${active === "articles" ? "active" : ''}`} to={`/${params.email}/articles`}>
              <Newspaper size={18}/>
              Articles
            </Link>
            {subpages.map(subpage => (
              <Link className={`link ${active === subpage.id ? "active" : ''}`}
                    to={`/${params.email}/${subpage.id}`}>
                {subpage.name}
              </Link>
            ))}
          </div>
        ) : (


          content === "admin" ? (
            <div className='links'>
              <Link className={`link ${active === "users" ? "active" : ''}`} to='/users'>
                <People size={18}/>
                Users
              </Link>
            </div>
          ) : (

            <>
              <div className='links'>
                <Link className={`link ${active === "recipes" ? "active" : ''}`} to='/recipes'>
                  <Cake2 size={18}/>
                  Recipes
                </Link>
                <Link className={`link ${active === "articles" ? "active" : ''}`} to='/articles'>
                  <Newspaper size={18}/>
                  Articles
                </Link>
                {!params.email && (
                  <>
                    <Link className={`link ${active === "subpages" ? "active" : ''}`} to='/subpages'>
                      <Files size={18}/>
                      Sub-pages
                    </Link>
                    <Link className={`link ${active === "reports" ? "active" : ''}`} to='/reports'>
                      <PieChartFill size={18}/>
                      Reports
                    </Link>
                  </>
                )}
              </div>
              {!params.email && (
                <SearchBar navigate={navigate}/>
              )}
            </>
          )

        )}

      </div>
      {!params.email && (
        <div className='dropdowns'>
          {role === "user" && (
            <Dropdown title={"Settings"} border>
              <Link to="/categories" className="option">
                Categories
              </Link>
              <Link to="/tags" className="option">
                Tags
              </Link>
              <Link to="/ingredients" className="option">
                Ingredients
              </Link>
            </Dropdown>
          )}
            <Dropdown title={
              <>
                {profilePicture !== 'null' ? (
                  <img
                    src={profilePicture}
                    alt="profile picture"
                    className="image"
                  />
                ) : (
                  <PersonCircle size={40}/>
                )}

                {userName}
              </>
            }>
              <button className="option" onClick={() => Logout(() => navigate('/login'))}>Log out</button>
            </Dropdown>

        </div>
      )}
    </div>
  );
};
export default Navbar;