import {Search, XLg} from "react-bootstrap-icons";
import {useState, useCallback} from "react";
import * as actions from "./SearchActions";
import { debounce } from "lodash"

const SearchBar = ({navigate}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchOptions, setSearchOptions] = useState([])

  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query.length > 2) {
        actions.searchAction(query, (data) => {
          const newOptions = [];

          Object.keys(data).forEach(key => {
            if (data[key].length) {
              newOptions.push(key);
            }
          });

          setSearchOptions(newOptions);
        });
      } else {
        setSearchOptions([]);
      }
    }, 300),
    []
  );

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);

    debouncedSearch(value);
  };

  return (
    <div className='searchBar'>
      <Search/>
      <input
        placeholder="Search"
        value={searchQuery}
        onChange={handleChange}
      />
      {searchQuery && (
        <>
        <XLg onClick={() => setSearchQuery("")} />
          {searchOptions.length>0 && (
            <div className="results">
              {searchOptions.map((searchResult) => (
                <div onClick={() => {setSearchQuery(''); navigate(`/search/${searchResult}/${searchQuery}`)}} className="result">
                  {searchQuery} <span>in:{searchResult}</span>
                </div>
              ))}
            </div>
          )}
        </>
      )}

    </div>
  )
}

export default SearchBar