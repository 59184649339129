import React, {useMemo} from "react";
import Table from "../../components/table/Table";


const DataTable = ({categories, chartData, amountOfCategories}) => {

  const columns = useMemo(
    () => [
      {
        Header: 'Color',
        accessor: 'color',
        disableSortBy: true,
        Cell: ({cell: {value}}) => (
          <div className='circle' style={{backgroundColor: value}}></div>
        )
      },
      {
        Header: 'Category',
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        disableSortBy: true,
      },
      {
        Header: 'Percent',
        accessor: 'percent',
        disableSortBy: true,
      },
    ],
    []
  );

  const tableData = useMemo(
    () =>
      categories.map((category, index) => ({
        color: chartData.datasets[0].backgroundColor[index%chartData.datasets[0].backgroundColor.length],
        name: chartData.labels[index],
        amount: chartData.datasets[0].data[index],
        percent: `${(chartData.datasets[0].data[index]/amountOfCategories*100).toFixed(1)}%`
      })),
    [categories, chartData, amountOfCategories]
  );


  return (
    <div className='datatable'>
      <Table
        columns={columns}
        data={tableData}
        striped
      />
    </div>
  )
}

export default DataTable