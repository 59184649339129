import axios from "axios";

export const loadRecipes = (data, callback) => {
  const config = {
    url: "/recipes",
    params: data
  };
  axios.request(config)
    .then((response) => callback(response.data))
};
export const loadRecipe = (id, callback) => {
  const config = {
    url: `/recipes/${id}`,
    data: {
      recipe: { id: id },
    },
  };
  axios.request(config)
    .then((response) => callback(response.data))
};

export const saveRecipe = (formData, callback) => {
  const config = {
    url: formData.id ? `/recipes/${formData.id}` : "/recipes",
    method: formData.id ? "PUT" : "POST",
    data: { recipe: formData },
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};
export const deleteRecipe = (id, callback) => {
  const config = {
    url: `/recipes/${id}`,
    method: "DELETE",
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};
