import * as actions from "./TagsActions.js";
import Container from "../../components/container/Container.js";
import Input from "../../components/form/input/Input";
import {Tags} from "react-bootstrap-icons";
import {useFormik} from "formik";
import * as yup from "yup";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";

const Tag = () => {
  const [initialValues, setInitialValues] = useState({
    id: null,
    name: ""
  })

  const navigate = useNavigate();
  const params = useParams();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      actions.saveTag({...values}, () =>
        navigate("/tags")
      );
    },
    validationSchema: yup.object({
      name: yup.string().required("Name is required"),
    }),
    validateOnChange: false,
  });

  useEffect(() => {
    if (params.id) {
      actions.loadTag(params.id, (data) => setInitialValues(data));
    }
  }, [params.id]);

  return (
    <>
      <Navbar />
      <form onSubmit={formik.handleSubmit}>
        <Container
          title={
            <div className="title">
              <Tags size={16}/>
              <span>Tags</span>
              <span>{`>`}</span>
              {formik.values.id ? (
                <span>edit - {formik.initialValues.name}</span>
              ) : (
                <span>New</span>
              )}
            </div>
          }
          onSave={() => formik.submitForm()}
        >
          <Input
            required={true}
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
          />
        </Container>
      </form>
    </>
  );
};

export default Tag;
