import "./Button.scss";
import {Link} from "react-router-dom";

const Button = ({onClick, type, variant, children, disabled}) => {
  return (
      <button
        onClick={onClick}
        disabled={disabled}
        type={type}
        className={`button ${variant}`}
      >
        {children}
      </button>
  );
};

export default Button;
