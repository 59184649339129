import Container from "../../components/container/Container";
import {Cake2, Newspaper, PencilSquare, QuestionCircleFill, Trash} from "react-bootstrap-icons";
import Table from "../../components/table/Table.js";
import * as actions from "./ArticlesActions.js";
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router";
import Modal from "../../components/modal/Modal.js";
import Button from "../../components/button/Button.js";
import {Link} from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import {useLocation} from 'react-router-dom';



const Articles = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const [articleToDelete, setArticleToDelete] = useState();
  const [articles, setArticles] = useState();
  const [sortBy, setSortBy] = useState({id: "id", desc: true});
  const [pages, setPages] = useState(0)


  const handleDelete = () => {
    const article = articleToDelete.original
    actions.deleteArticle(article.id, () => {
      setArticles(
        articles => articles.filter((_, index) => index != articleToDelete.id)
      )
      setArticleToDelete()
    });
  };

  const columns = React.useMemo(() => [
    {
      Header: "Article",
      accessor: "name",
      sortType: "alphanumeric",
      Cell: (tableProps) => (
        <div className="col">
          {tableProps.row.original.image.image_url ? (
            <img
              src={tableProps.row.original.image.image_url}
              alt="icon"
              className="image"
            />
          ) : (
            <QuestionCircleFill className="no-image-icon" size={40}/>
          )}
          {tableProps.row.original.name}
        </div>
      ),
    },
    {
      Header: "Published",
      accessor: "created_at",
      Cell: (tableProps) => (
        <>{new Date(tableProps.row.original.created_at).toLocaleDateString('en-GB')}</>
      ),
    },
    {
      Header: "Options",
      Cell: (tableProps) => (
        <div className="options">
          <Link to={`/article/${tableProps.row.original.id}`}>
            <PencilSquare size={20}/>
          </Link>
          <a>
            <Trash
              onClick={() => setArticleToDelete(tableProps.row)}
              size={20}
            />
          </a>
        </div>
      ),
      disableSortBy: true,
    },
  ]);


  useEffect(() => {
    actions.loadArticles(
      {
        sort: sortBy.id.concat(sortBy.desc ? " DESC" : " ASC")
      },
      (data) => {
        setArticles(data.articles)
        setPages(data.total_pages)
      }
    );
  }, [sortBy]);
  return (
    <div className='articles'>
      <Navbar active="articles"/>
      <Modal
        isOpen={articleToDelete}
        title={
          <div className="title">
            <span>Articles</span>
            <span>{`>`}</span>
            <span>Delete {articleToDelete?.original.name}</span>
          </div>
        }
        footer={
          <>
            <Button variant='secondary' onClick={() => setArticleToDelete()}>
              Cancel
            </Button>
            <Button variant='danger' onClick={() => handleDelete()}>
              Delete
            </Button>
          </>
        }
      />
      <Container
        title={
          <div className="title">
            <Newspaper size={16}/>
            <span>Articles</span>
          </div>
        }
        onAdd={() => navigate('/article')}
      >
        {articles && (
          <Table
            columns={columns}
            data={articles}
            onSortChange={setSortBy}
            onPageChange={(e) => {
              actions.loadArticles({
                sort: sortBy.id.concat(sortBy.desc ? " DESC" : " ASC"),
                page: e.pageIndex + 1
              }, (data) => setArticles(data.articles));
            }}
            pageCount={pages}
          />
        )}
      </Container>
    </div>
  );
};
export default Articles;
