import axios from "axios";

export const loadArticles = (data, callback) => {
  const config = {
    url: `/public/articles/`,
    params: data
  };
  axios.request(config)
    .then((response) => callback(response.data))
};
export const loadArticle = (params, callback) => {
  const config = {
    url: `/public/articles/${params.id}`,
    params
  };
  axios.request(config)
    .then((response) => callback(response.data))
};