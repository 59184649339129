import axios from "axios";

export const loadUsers = (page, callback) => {
  const config = {
    url: "/users",
    params: page
  };
  axios.request(config)
    .then((response) => callback(response.data))
};
export const loadUser = (id, callback) => {
  const config = {
    url: `/users/${id}`,
    data: {
      user: {id: id},
    },
  };
  axios.request(config)
    .then((response) => callback(response.data))
};

export const saveUser = (formData, callback) => {
  const config = {
    url: formData.id ? `/users/${formData.id}` : "/users",
    method: formData.id ? "PUT" : "POST",
    data: {user: formData},
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};

export const deleteUser = (id, callback) => {
  const config = {
    url: `/users/${id}`,
    method: "DELETE",
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};