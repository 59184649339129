import Navbar from "../../components/navbar/Navbar";
import Container from "../../components/container/Container";
import {Newspaper} from "react-bootstrap-icons";
import * as actions from "./ArticlesActions"
import {useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";
import * as yup from "yup";
import Wysiwyg from "../../components/form/wysiwyg/Wysiwyg";
import Input from "../../components/form/input/Input";
import Button from "../../components/button/Button";
import React, {useEffect, useState} from "react";
import "./Articles.scss"
import DropZone from "../../components/form/dropzone/DropZone";

const Article = () => {
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState({})
  const params = useParams()

  useEffect(() => {
    if (params.id) {
      actions.loadArticle(params.id, (data) => setInitialValues(data));
    }
  }, [params.id]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      actions.saveArticle({...values, photo_id: values.image?.id}, () => navigate("/articles"))
    },
    validationSchema: yup.object({
      name: yup.string().required("Name is required"),
      content: yup
        .string()
        .required("Content is required")
        .notOneOf(['<p></p>','<p><br></p>'], "Content is required"),
    }),
    validateOnChange: false,
  });


  return (
    <div className='article'>
      <Navbar active="articles"/>
      <form onSubmit={formik.handleSubmit}>
        <Container
          title={
            <div className="title">
              <Newspaper size={16}/>
              <span>Articles</span>
              <span>{`>`}</span>
              {formik.values.id ? (
                <span>{formik.initialValues.name}</span>
              ) : (
                <span>Write new Article</span>
              )}
            </div>
          }
        >
          <Input
            required
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
            label="Name"
          />
          <Wysiwyg
            required
            label="content"
            name="content"
            value={formik.values.content}
            error={formik.errors.content}
            onChange={inputedText => formik.setFieldValue("content", inputedText)}
          />
          <DropZone
            image={formik.values.image}
            label="Photo"
            variant="big"
            onChange={(photo) => formik.setFieldValue("image", photo)}
          />
          <div className='save'>
            <Button variant='primary'>Save</Button>
          </div>
        </Container>
      </form>
    </div>
  )
}

export default Article