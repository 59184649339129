import Navbar from "../../components/navbar/Navbar";
import Container from "../../components/container/Container";
import {FileEarmark, Files, PencilSquare, Trash} from "react-bootstrap-icons";
import "./Subpages.scss";
import * as actions from "./SubpagesActions"
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState, useRef} from "react";
import Button from "../../components/button/Button";
import Modal from "../../components/modal/Modal";
import {reorderSubpages, saveSubpage} from "./SubpagesActions";

const Subpages = () => {
  const navigate = useNavigate()
  const [subpages, setSubpages] = useState()
  const [subpageToDelete, setSubpageToDelete] = useState()
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  useEffect(() => {
    actions.loadSubpages((data) => setSubpages(data))
  }, []);



  const handleDelete = () => {
    actions.deleteSubpage(subpageToDelete.id, () => {
      setSubpages(
        subpages => subpages.filter((subpage) => subpage.id != subpageToDelete.id)
      )
      setSubpageToDelete()
    });
  };

  const handleDragStart = (index) => {
    dragItem.current = index;
  };

  const handleDragEnter = (index) => {
    dragOverItem.current = index;
  };

  const handleDragEnd = () => {
    const draggedItemIndex = dragItem.current;
    const targetItemIndex = dragOverItem.current;

    if (draggedItemIndex !== targetItemIndex) {
      const updatedSubpages = [...subpages];
      const draggedItem = updatedSubpages[draggedItemIndex];

      updatedSubpages.splice(draggedItemIndex, 1);

      updatedSubpages.splice(targetItemIndex, 0, draggedItem);

      const reorderedSubpages = updatedSubpages.map((subpage, index) => ({
        ...subpage,
        position: index,
      }));

      setSubpages(reorderedSubpages);

      reorderSubpages(reorderedSubpages, () => {})

    }

    dragItem.current = null;
    dragOverItem.current = null;
  };


  return (
    <div className='subpages'>
      <Navbar active="subpages"/>
      <Modal
        isOpen={subpageToDelete}
        title={
          <div className="title">
            <span>Subpages</span>
            <span>{`>`}</span>
            <span>Delete {subpageToDelete?.name}</span>
          </div>
        }
        footer={
          <>
            <Button variant='secondary' onClick={() => setSubpageToDelete()}>
              Cancel
            </Button>
            <Button variant='danger' onClick={() => handleDelete()}>
              Delete
            </Button>
          </>
        }
      />
      <Container
        onAdd={() => navigate('/subpage')}
        title={
          <div className="title">
            <Files size={16}/>
            <span>Main navigation</span>
          </div>
        }
      >
        <div className='page'>
          <span><FileEarmark size={20}/> Recipes</span>
        </div>
        <div className='page'>
          <span><FileEarmark size={20}/> Articles</span>
        </div>
        {subpages && (
          subpages.map((subpage, index) => (
            <div
              className='page'
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragEnter={() => handleDragEnter(index)}
              onDragEnd={handleDragEnd}
              onDragOver={(e) => e.preventDefault()}
            >
              <span><FileEarmark size={20}/> {subpage.name}</span>
              <div className="options">
                <Link to={`/subpage/${subpage.id}`}>
                  <PencilSquare size={20}/>
                </Link>
                <a>
                  <Trash
                    onClick={() => setSubpageToDelete(subpage)}
                    size={20}
                  />
                </a>
              </div>
            </div>
          ))
        )}

      </Container>
    </div>
  )
}

export default Subpages