import Button from "../button/Button";
import "./Container.scss"
import classNames from "classnames";
import DatePicker from "../datePicker/DatePicker";
import {Funnel} from "react-bootstrap-icons";
import {useState} from "react";

const Container = ({title, onSave, onAdd, children, className, onDateFilter, footer}) => {
  const [toggleFilter, setToggleFilter] = useState(false)

  return (
    <div className='container'>
      {title && (
        <div className="head">
          {title}
          {onSave && <Button onClick={onSave} variant='primary' type="button">Save</Button>}
          {onAdd && (
            <Button onClick={onAdd} variant='primary' to="/category">+ Add new</Button>
          )}
          {onDateFilter && (
            <div className='datefilter'>
              <Button onClick={() => setToggleFilter(currentState => !currentState)} variant='primary'><Funnel/> Filters</Button>
              {toggleFilter && <DatePicker onSave={onDateFilter} onCancel={() => setToggleFilter(false)}/>}
            </div>
          )}
        </div>
      )}
      <div className={classNames('content', className)}>
        {children}
      </div>
      {footer && (
        <>
          {footer}
        </>
      )}
    </div>
  )
}

export default Container