import Navbar from "../../components/navbar/Navbar";
import Container from "../../components/container/Container";
import {Files} from "react-bootstrap-icons";
import "./Subpage.scss";
import * as actions from "./SubpagesActions"
import {useNavigate, useParams} from "react-router-dom";
import {useFormik} from "formik";
import * as yup from "yup";
import Wysiwyg from "../../components/form/wysiwyg/Wysiwyg";
import Input from "../../components/form/input/Input";
import Button from "../../components/button/Button";
import React, {useEffect, useState} from "react";

const Subpage = () => {
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState({})
  const params = useParams()

  useEffect(() => {
    if (params.id) {
      actions.loadSubpage(params.id, (data) => setInitialValues(data));
    }
  }, [params.id]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      actions.saveSubpage(values, () => navigate("/subpages"))
    },
    validationSchema: yup.object({
      name: yup.string().required("Name is required"),
      content: yup
        .string()
        .required("Content is required")
        .notOneOf(['<p></p>','<p><br></p>'], "Content is required"),
    }),
    validateOnChange: false,
  });


  return (
    <div className='subpage'>
      <Navbar active="subpages"/>
      <form onSubmit={formik.handleSubmit}>
        <Container
          title={
            <div className="title">
              <Files size={16}/>
              <span>Main navigation</span>
              <span>{`>`}</span>
              {formik.values.id ? (
                <span>{formik.initialValues.name}</span>
              ) : (
                <span>Add new subpage</span>
              )}
            </div>
          }
        >
        <Input
            required
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
            label="Name"
          />
          <Wysiwyg
            required
            label="content"
            name="content"
            value={formik.values.content}
            error={formik.errors.content}
            onChange={inputedText => formik.setFieldValue("content", inputedText)}
          />
          <div className='save'>
            <Button variant='primary'>Save</Button>
          </div>
        </Container>
      </form>
    </div>
  )
}

export default Subpage