import Container from "../../../components/container/Container";
import {ListTask, PencilSquare, People, PersonCircle, QuestionCircleFill, Trash} from "react-bootstrap-icons";
import Table from "../../../components/table/Table.js";
import * as actions from "./UsersActions.js";
import {useState, useEffect} from "react";
import {useNavigate} from "react-router";
import Modal from "../../../components/modal/Modal.js";
import Button from "../../../components/button/Button.js";
import React from "react";
import {Link} from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";

const Users = () => {
  const navigate = useNavigate();
  const [userToDelete, setUserToDelete] = useState();
  const [users, setUsers] = useState();
  const [pages, setPages] = useState(0);
  const handleDelete = () => {

    actions.deleteUser(userToDelete.id, () => {
      setUsers(
        users => users.filter((user) => user.id != userToDelete.id)
      )
      setUserToDelete()
    });
  };

  useEffect(() => {
    actions.loadUsers({}, data => {
      setUsers(data.users)
      setPages(data.total_pages)
    });

  }, []);

  const columns = React.useMemo(() => [
    {
      Header: "User",
      accessor: "name",
      disableSortBy: true,
      Cell: (tableProps) => (
        <div className="col">
          {tableProps.row.original.image.image_url ? (
            <img
              src={tableProps.row.original.image.image_url}
              alt="icon"
              className="image"
            />
          ) : (
            <PersonCircle size={40}/>
          )}
          {tableProps.row.original.name} {tableProps.row.original.surname}
        </div>
      ),
    },
    {
      Header: "Options",
      disableSortBy: true,
      Cell: (tableProps) => (
        <div className="options">
          <Link to={`/user/${tableProps.row.original.id}`}>
            <PencilSquare size={20}/>
          </Link>
          <Trash
            onClick={() => setUserToDelete(tableProps.row.original)}
            size={20}
          />
        </div>
      ),
    },
  ]);

  return (
    <>
      <Navbar content='admin' active="users"/>
      <Modal
        isOpen={userToDelete}
        title={
          <div className="title">
            <span>Users</span>
            <span>{`>`}</span>
            <span>Delete {userToDelete?.name} {userToDelete?.surname}</span>
          </div>
        }
        footer={
          <>
            <Button variant='secondary' onClick={() => setUserToDelete()}>
              Cancel
            </Button>
            <Button variant='danger' onClick={() => handleDelete(userToDelete)}>
              Delete
            </Button>
          </>
        }
      />
      <Container
        title={
          <div className="title">
            <People size={16}/>

            <span>Users</span>
          </div>
        }
        onAdd={() => navigate('/user')}
      >
        {users && (
          <Table
            columns={columns}
            data={users}
            onPageChange={(page) => {actions.loadUsers({page: page.pageIndex+1}, (data) => setUsers(data.users));}}
            pageCount={pages}
          />
        )}
      </Container>
    </>
  );
};
export default Users;