import Container from "../../components/container/Container";
import {ListTask, PencilSquare, QuestionCircleFill, Trash} from "react-bootstrap-icons";
import Table from "../../components/table/Table.js";
import * as actions from "./CategoriesActions.js";
import {useState, useEffect} from "react";
import {useNavigate} from "react-router";
import Modal from "../../components/modal/Modal.js";
import Button from "../../components/button/Button.js";
import React from "react";
import {Link} from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";

const Categories = () => {
  const navigate = useNavigate();
  const [categoryToDelete, setCategoryToDelete] = useState();
  const [categories, setCategories] = useState();
  const [pages, setPages] = useState();
  const handleDelete = () => {
    if (!categoryToDelete.has_recipes) {
      actions.deleteCategory(categoryToDelete.id, () => {
        setCategories(
          categories => categories.filter((category) => category.id != categoryToDelete.id)
        )
        setCategoryToDelete()
      });
    }
  };

  useEffect(() => {
    actions.loadCategories({}, data => {
      setCategories(data.categories)
      setPages(data.total_pages)
    });
  }, []);

  const columns = React.useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      disableSortBy: true,
      Cell: (tableProps) => (
        <div className="col">
          {tableProps.row.original.image.image_url ? (
            <img
              src={tableProps.row.original.image.image_url}
              alt="icon"
              className="image"
            />
          ) : (
            <QuestionCircleFill className="no-image-icon" size={40}/>
          )}
          {tableProps.row.original.name}
        </div>
      ),
    },
    {
      Header: "Options",
      disableSortBy: true,
      Cell: (tableProps) => (
        <div className="options">
          <Link to={`/category/${tableProps.row.original.id}`}>
            <PencilSquare size={20}/>
          </Link>
          <Trash
            onClick={() => setCategoryToDelete(tableProps.row.original)}
            size={20}
          />
        </div>
      ),
    },
  ]);

  return (
    <>
      <Navbar/>
      <Modal
        isOpen={categoryToDelete}
        title={
          <div className="title">
            <span>Categories</span>
            <span>{`>`}</span>
            <span>Delete {categoryToDelete?.name}</span>
          </div>
        }
        footer={
          <>
            <Button variant='secondary' onClick={() => setCategoryToDelete()}>
              Cancel
            </Button>
              <Button disabled={categoryToDelete?.has_recipes} variant='danger' onClick={() => handleDelete()}>
                Delete
              </Button>
          </>
        }
      />
      <Container
        title={
          <div className="title">
            <ListTask size={16}/>

            <span>Categories</span>
          </div>
        }
        onAdd={() => navigate('/category')}
      >
        {categories && (
          <Table
            columns={columns}
            data={categories}
            onPageChange={(page) => {actions.loadCategories({page: page.pageIndex+1}, (data) => setCategories(data.categories));}}
            pageCount={pages}
          />
        )}
      </Container>
    </>
  );
};
export default Categories;
