import {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import "./DropZone.scss"
import {ReactComponent as uploadIcon} from "./upload.svg";
import Icon from "../../icons/Icon";
import * as actions from '../FormActions'

const DropZone = ({onChange, label, required, image, variant}) => {
  const maxSize = 30 * 1024 * 1024

  const acceptedFileTypes = {
    'image/*': [],
  };

  const handleFileChange = (file) => {
    let formData = new FormData()
    formData.append("image", file)
    actions.savePhoto(formData, (photo) => {
      onChange(photo);
    })

  };

  const onDrop = useCallback((acceptedFiles) => {
    handleFileChange(acceptedFiles[0]);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    maxSize,
    accept: acceptedFileTypes,
  });

  return (
    <div className="dropzone" {...getRootProps()}>
      <div className="label">
        {required && <span>*</span>}
        {label}
      </div>
      <label htmlFor="file" className="custom-file-upload">
        {!!image?.image_url ? (
          <img
            alt="preview"
            width="100"
            className={`image ${variant}`}
            src={image.image_url}
          />

        ) : (
          <Icon icon={uploadIcon}/>
        )}
        {!(variant==="big" && !!image?.image_url) && (
          <div className="text">
            <span>
              {isDragActive ? (
                <span>Drop here</span>
              ) : (
                <>
                  <span>Click to upload</span> or drag and drop
                </>
              )}
            </span>
            <div>
              <span>(Max file size: 30MB)</span>
            </div>
          </div>
        )}
        <input {...getInputProps()} />
      </label>
    </div>
  );
};

export default DropZone