import axios from "axios";

export const searchAction = (query, callback) => {
  const config = {
    url: "/search",
    method: "GET",
    params: {query: query},
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};