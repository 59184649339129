import axios from "axios";

export const saveArticle = (formData, callback) => {
  const config = {
    url: formData.id ? `/articles/${formData.id}` : "/articles",
    method: formData.id ? "PUT" : "POST",
    data: {article: formData},
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};
export const loadArticles = (data, callback) => {
  const config = {
    url: "/articles",
    params: data
  };
  axios.request(config)
    .then((response) => callback(response.data))
};

export const loadArticle = (id, callback) => {
  const config = {
    url: `/articles/${id}`,
    data: {
      article: { id: id },
    },
  };
  axios.request(config)
    .then((response) => callback(response.data))
};

export const deleteArticle = (id, callback) => {
  const config = {
    url: `/articles/${id}`,
    method: "DELETE",
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};