import axios from "axios";

export const searchItems = (query, type, callback) => {
  const config = {
    url: `/search/${type}`,
    method: "GET",
    params: {query: query},
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};

export const deleteItem = (id, itemType, callback) => {
  const config = {
    url: `/${itemType}/${id}`,
    method: "DELETE",
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};
