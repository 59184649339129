import axios from "axios";

export const loadSubpages = (params, callback) => {
  const config = {
    url: '/public/subpages',
    params
  }
  const response = axios.request(config)
    .then(response => {
      callback(response.data)
    })
}