import axios from "axios";

export const loadTags = (page, callback) => {
  const config = {
    url: "/tags",
    params: page
  };
  axios.request(config)
    .then((response) => callback(response.data))
};
export const loadTag = (id, callback) => {
  const config = {
    url: `/tags/${id}`,
    data: {
      tag: {id: id},
    },
  };
  axios.request(config)
    .then((response) => callback(response.data))
};

export const saveTag = (formData, callback) => {
  const config = {
    url: formData.id ? `/tags/${formData.id}` : "/tags",
    method: formData.id ? "PUT" : "POST",
    data: {tag: formData},
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};

export const deleteTag = (id, callback) => {
  const config = {
    url: `/tags/${id}`,
    method: "DELETE",
  };
  const result = axios.request(config)
    .then((response) => callback(response.data))
};
